<template>
  <div class="credit_validation_wrap">
    <div class="credit_validation_head_title">
      <i class="credit_validation_head_title_ico" @click="goBack">
        <img src="~@/assets/images/credit/go_back.png" alt="">
      </i>
      One Time Password
    </div>
    <div class="credit_validation_form_container">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="0px">
        <el-form-item label="" prop="code">
          <el-input v-model="form.code" placeholder="One Time Password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="credit_validation_form_proceed" type="primary" @click="onSubmit" :loading="proceedLoading">Proceed</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { cirReportExp } from '@/api/modules/credit'

export default {
  data () {
    const validateCode = (rule, value, callback) => {
        const reg = /^\d{6}$/
        const msg = 'Must be a 6 digit number.'
        value = value.trim()
        this.form.code = value
        if (!reg.test(value)) {
          if (value.length === 0) {
            callback(new Error('Required field'))
          } else {
            callback(new Error(msg))
          }
        } else {
          if (value.length === 0) {
            callback(new Error('Required field'))
          } else {
            callback()
          }
        }
      }
    return {
      proceedLoading: false,
      form: {
        code: ''
      },
      rules: {
        code: [
          { required: true, message: 'Required field.'},
          { validator: validateCode, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    txId() {
      return this.$route.query.txId
    },
    sourceUrl() {
      return this.$route.query.sourceUrl
    }
  },
  created() {
    // this.sourceUrl = this.$route.query.sourceUrl
    // let ff = 'https://www.baidu.com/?ffff=e4cac6a0f283d38a&dd=2342'
    // ff = encodeURIComponent(ff)
    // console.log(ff)
    // ff = decodeURIComponent(ff)
    // console.log(ff)
  },
  methods: {
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.proceedLoading = true
          this._setCirReportExp()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    async _setCirReportExp() {
      // const sourceUrlStr = this.sourceUrl
      // this.sourceUrl = decodeURI(sourceUrlStr)
      // window.open(`${this.sourceUrl}?txId=${this.txId}`)
      try{
        const params = {
          txId: this.txId,
          otp: this.form.code,
          withoutData: true
        }
        const { data } = await cirReportExp(params)

        if (data.code !== 'SUCCESS') {
          this.$message({message: data.message, type: 'error', customClass: 'message_box'})
        } else {
          let sourceUrlStr = this.sourceUrl
          // this.$message({message: `${sourceUrlStr}?txId=${this.txId}`, type: 'success', customClass: 'message_box'})
          if (sourceUrlStr) {
            sourceUrlStr = decodeURIComponent(sourceUrlStr)
            let url = ''
            if (sourceUrlStr.indexOf("?") !== -1) {
              url = `${sourceUrlStr}&txId=${this.txId}`
            } else {
              url = `${sourceUrlStr}?txId=${this.txId}`
            }
            this.openUrl(url)
          } else {
            this.$message({message: "Can't find the source url.", type: 'error', customClass: 'message_box'})
          }
        }
      } catch (e) {
        this.$message({message: e.message, type: 'error', customClass: 'message_box'})
      } finally {
        this.proceedLoading = false
      }
    },
    openUrl(url) {
        let tempALink = document.createElement("a")
        tempALink.setAttribute("target", "_top")
        tempALink.setAttribute("id", "openWin")
        tempALink.setAttribute("href", url)
        if(!document.getElementById('openWin')) document.body.appendChild(tempALink);
        document.getElementById("openWin").click()
        document.body.removeChild(tempALink) 
    }
  }
}
</script>

<style lang="scss">
.credit_validation_wrap{
  .el-form-item.is-success .el-input__inner, .el-form-item.is-success .el-input__inner:focus, .el-form-item.is-success .el-textarea__inner, .el-form-item.is-success .el-textarea__inner:focus{
    border-color: green;
  }
  .el-form-item{
    margin-bottom: 30px;
  }
  .el-input.is-active .el-input__inner, .el-input__inner:focus, .el-textarea__inner:focus, .el-select .el-input.is-focus .el-input__inner{
    border-color: green;
  }
  .el-input__inner{
    color: #000000;
    font-weight: 700;
  }
}
</style>
<style lang="scss" scoped>
.credit_validation_wrap{
  // padding: 30px ;
  .credit_validation_head_title{
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    padding: 13px 0;
    color: #000000;
    line-height: 17px;
    font-weight: bold;
    position: relative;
    top: 0;
    left: 0;
    .credit_validation_head_title_ico{
      position: absolute;
      top: 9px;
      left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .credit_validation_form_container{
    padding: 32px 16px;
  }
  .credit_validation_form_proceed{
    margin-top: 22px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    border-radius: 25px;
    border: 0px transparent solid;
    border: none;
    background: -webkit-linear-gradient(92.22deg, #5DD36E 8.52%, #30B043 99.3%);
    /* Safari 5.1 - 6.0*/
    background: -o-linear-gradient(92.22deg, #5DD36E 8.52%, #30B043 99.3%);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(92.22deg, #5DD36E 8.52%, #30B043 99.3%);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(92.22deg, #5DD36E 8.52%, #30B043 99.3%);
  }
}
</style>
